// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

// Models
import { ErrorMessage, BookingAgentInformation } from '@allianz/agent-max-core-lib';
import { Agencies } from '../models/interfaces';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UserService } from '../api/services/user.service';
import { GdsService } from 'src/app/api/services/gds.service';

// Third Party
import { Subscription } from 'rxjs/internal/Subscription';
import { LoadingService } from '../api/services/loading.service';

@Component({
  selector: 'app-switch-agency',
  templateUrl: './switch-agency.component.html',
  styleUrls: ['./switch-agency.component.scss']
})
export class SwitchAgencyComponent implements OnInit, OnDestroy {
  validationMessages: ErrorMessage[] = [];
  switchAgencySubscriptions: Subscription[] = [];
  branches: Agencies[] = [];
  agencies: Agencies[] = [];
  hideBranches: boolean = true;
  hideAgencies: boolean = true;
  switchAgencyFormGroup: FormGroup;
  selectedAgencyId: FormControl = new FormControl(0);
  selectedBranchId: FormControl = new FormControl(0);
  agentInformation: BookingAgentInformation;

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private userService: UserService,
    private gdsService: GdsService, 
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.switchAgencyFormGroup = this.formBuilder.group({
      selectedAgencyId: this.selectedAgencyId,
      selectedBranchId: this.selectedBranchId
    });
  }

  ngOnInit(): void {
    this.agentInformation = this.appStateService.getAgentInformation();
    this.getAgencies();
    this.selectedAgencyId.setValue(this.agentInformation.AgencyId);
    this.selectedBranchId.setValue(this.agentInformation.BranchId);

  }

  ngOnDestroy(): void {
    this.switchAgencySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * event handler for the select button next to the agency drop down
   * @returns {void}
   */
  onSelectAgency(): void {
    this.getBranches();
  }

  /**
   * event handler for the select button next to the branch drop down
   * calls the switch agency function, which actually does the work
   * @returns {void}
   */
  onSelectBranch(): void {
    this.switchAgency(this.switchAgencyFormGroup.value.selectedBranchId);
  }

  /**
   * ♫ Home, home is a fire
   *     A burning reminder
   *           Of where we belong, oh ♪
   * @returns {void}
   */
  goHome(): void {
    this.router.navigate(['home']);
  }

  /**
   * Gets the list of agencies for the switch agency dropdown by user in session
   * @returns {void}
   */
  getAgencies(): void {
    this.loadingService.open();
    this.switchAgencySubscriptions.push(
      this.userService.getAgenciesToSwitch(this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.IsValid) {
            this.agencies = response.Agencies;
            this.hideAgencies = (this.agencies.length === 1);
            this.getBranches();
          }
          this.validationMessages = response.ErrorMessages;
        },
        (err) => {
          this.validationMessages = err.ErrorMessages;
        },
        () => this.loadingService.close())
    );
  }

  /**
   * Gets the list of branches for the switch agency dropdown by user and agencyid in form
   * @returns {void}
   */
  getBranches(): void {
    this.loadingService.open();
    this.switchAgencySubscriptions.push(
      this.userService.getBranchesToSwitch(this.switchAgencyFormGroup.value.selectedAgencyId, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.IsValid) {
            if (response.Agencies.length === 1) {
              this.hideBranches = true;
              this.selectedBranchId.setValue(this.switchAgencyFormGroup.value.selectedAgencyId);
              if (this.agentInformation.BranchId !== this.switchAgencyFormGroup.value.selectedBranchId) {
                this.switchAgency(this.switchAgencyFormGroup.value.selectedAgencyId);
              }
            } else {
              this.branches = response.Agencies;
              this.hideBranches = false;
            }
          }
          this.validationMessages = response.ErrorMessages;
        },
        (err) => {
          this.validationMessages = err.ErrorMessages;
        },
        () => this.loadingService.close())
    );
  }

  /**
   * updates the current users selected agency, then brings them to the home page
   * @param {number} agencyId
   * @returns {void}
   */
  switchAgency(agencyId: number): void {
    this.validationMessages = [];
    this.loadingService.open();
    this.switchAgencySubscriptions.push(
      this.userService.switchAgency(agencyId, this.appStateService.getCredentials())
        .subscribe((response) => {
          this.loadingService.close();
          if (response.ErrorMessages.length === 0) {
            this.appStateService.setCredentials(response);
            this.appStateService.setAdminStatus(response.IsAdmin);
            this.appStateService.setReportingRight(response.HasReportingRight);
            this.appStateService.setAgentInformation(null);
            this.appStateService.setCanManageGroups(null);
            if (this.appStateService.getGUID()) {
              this.gdsService.registerGDSLogin(response, this.appStateService.getGUID())
              .subscribe((response) => {
                if (response.ErrorMessages.length > 0) {
                  this.validationMessages = response.ErrorMessages;
                }
              });
            }
            this.goHome();
          }
          this.validationMessages = response.ErrorMessages;
        },
        (err) => {
          this.validationMessages = err.ErrorMessages;
        },
        () => this.loadingService.close())
    );
  }

}
