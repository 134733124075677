// Angular Core
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Models
import { Booking, OrderInformation, State, ErrorMessage, BookingTypes, BookingAgentInformation } from '@allianz/agent-max-core-lib';
import { environment } from 'src/environments/environment';
import { InsurancePaymentMethodType } from 'src/app/models/enums';

// Services
import { AppStateService } from '../../services/app-state.service';
import { UniversalConfigService } from 'src/app/api/services/universal-config.service';

// Third Party
import { Observable, Subscription } from 'rxjs';
import { AccordionConfig } from 'ngx-bootstrap';
import { GDSInterfaceSettings } from 'src/app/models/interfaces';

// TODO: remove and use whats in the utility service
export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { isAnimated: true });
}

declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [{ provide: AccordionConfig, useFactory: getAccordionConfig }]
})
export class CheckoutComponent implements OnInit, OnDestroy {

  agentInfo: BookingAgentInformation;

  /**
   * gets the original price
   * @returns {number}
   */
  get originalPrice(): number {
    return this._originalOrder.Price;
  }

  /**
   * Gets the months
   * @returns {Array<{value: number, text: string}>}
   */
  get months(): Array<{ value: number; text: string; }> {
    return this._months;
  }

  /**
   * Gets the years
   * @returns {Array<{value: number, text: string}>}
   */
  get years(): Array<{ value: number; text: string; }> {
    return this._years;
  }

  /**
   * Sets the years
   * @returns {Array<{value: number, text: string}>}
   */
  set years(val: Array<{ value: number; text: string; }>) {
    this._years = val;
  }

  /**
   * Gets the original total trip cost
   * @returns {number}
   */
  get originalTotalTripCost(): number {
    return this._originalOrder.Price;
  }
  checkoutForm: FormGroup;
  billingInfoValidation: ErrorMessage[] = [];
  purchaseErrors: ErrorMessage[] = [];
  states: Observable<State[]>;
  submit: Observable<void>;
  isOpen: boolean = true;
  isLoaded: boolean = false;
  showPurAckError: boolean = false;
  viewType: string;
  priceBeforeModify: number;
  @Input('isModify') isModify: boolean = false;
  @Input('model') OrderInformation: OrderInformation;
  @Input('booking') booking: Booking;
  @Input('quoteChanged') quoteChanged: boolean;
  @Input('recalculateValid') recalculateValid: boolean;
  @Input('canSaveRecord') canSaveRecord: boolean;

  // these kick the service call up to the parent component, so it can handle all the complicated save logic
  @Output() bookingPurchase: EventEmitter<string> = new EventEmitter<string>();
  @Output() retryPendingBooking: EventEmitter<string> = new EventEmitter<string>();
  @Output() reCalculatePrice: EventEmitter<string> = new EventEmitter<string>();
  @Output() isCheckPriceButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modifyPurchase: EventEmitter<string> = new EventEmitter<string>();
  @Output() updateBooking: EventEmitter<string> = new EventEmitter<string>();
  @Output() bookingSaveQuote: EventEmitter<string> = new EventEmitter<string>();

  isFulfillmentByMail: FormControl = new FormControl(false);
  primaryEmail: FormControl = new FormControl('');
  secondaryEmail: FormControl = new FormControl('');
  addressLine1: FormControl = new FormControl('');
  addressLine2: FormControl = new FormControl('');
  city: FormControl = new FormControl('');
  stateOfResidence: FormControl = new FormControl('');
  zipCode: FormControl = new FormControl('');
  readPurchaseAgreement: FormControl = new FormControl(false);
  readCertificate: FormControl = new FormControl(false);
  sameAddress: FormControl = new FormControl(true);
  nameOnCard: FormControl = new FormControl('');
  cardNumber: FormControl = new FormControl('');
  expMonth: FormControl = new FormControl('');
  expYear: FormControl = new FormControl('');
  paymentMethod: FormControl = new FormControl(InsurancePaymentMethodType.CC);
  isPayMethodCashWithheld: FormControl = new FormControl(false);
  receiptNumber: FormControl = new FormControl('');
  checkoutSubscription: Subscription;
  isPendingPolicyView: boolean = false;
  isEditFlightsPolicyView: boolean = false;
  isPolicyView: boolean = false;
  ccData: any;
  paymentMethodType: typeof InsurancePaymentMethodType = InsurancePaymentMethodType;
  checkPriceButtonClick: boolean = false;
  isSmallGroupPolicy: boolean = false;
  private _originalOrder: OrderInformation;
  private _months: Array<{ value: number; text: string; }> = [
    { value: 1, text: `January` },
    { value: 2, text: `February` },
    { value: 3, text: `March` },
    { value: 4, text: `April` },
    { value: 5, text: `May` },
    { value: 6, text: `June` },
    { value: 7, text: `July` },
    { value: 8, text: `August` },
    { value: 9, text: `September` },
    { value: 10, text: `October` },
    { value: 11, text: `November` },
    { value: 12, text: `December` }
  ];
  private _years: Array<{ value: number; text: string; }>;
  gdsInterfaceSettings: GDSInterfaceSettings[];
  isDeliveryAddressEnabled: boolean = false;
  isBillingAddressEnabled: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private universalConfigService: UniversalConfigService,
    private appStateService: AppStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.checkoutForm = this.formBuilder.group({
      isFulfillmentByMail: this.isFulfillmentByMail,
      primaryEmail: this.primaryEmail,
      secondaryEmail: this.secondaryEmail,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      stateOfResidence: this.stateOfResidence,
      zipCode: this.zipCode,
      readPurchaseAgreement: this.readPurchaseAgreement,
      readCertificate: this.readCertificate,
      sameAddress: this.sameAddress,
      nameOnCard: this.nameOnCard,
      cardNumber: this.cardNumber,
      expMonth: this.expMonth,
      expYear: this.expYear,
      paymentMethod: this.paymentMethod,
      isPayMethodCashWithheld: this.isPayMethodCashWithheld,
      receiptNumber: this.receiptNumber
    });
  }

  ngOnInit(): void {
    this.agentInfo = this.appStateService.getAgentInformation();
    this.isSmallGroupPolicy = this.router.url.indexOf('modify-individual-policy') !== -1 || this.router.url.indexOf('add-new-policy') !== -1;
    this.priceBeforeModify = this.booking.OrderInformation.Price;
    this.isPendingPolicyView = this.appStateService.getBookingInfoViewType() === BookingTypes.Pending;
    this.isPolicyView = this.appStateService.getBookingInfoViewType() === BookingTypes.Policy;
    this.isEditFlightsPolicyView = this.appStateService.getBookingInfoViewType() === BookingTypes.EditFlights;
    this.paymentMethod.setValue(this.booking.OrderInformation == null ? InsurancePaymentMethodType.CC : this.booking.OrderInformation.PaymentMethod);
    //based on the interface settings the billing address checkbox should be checked 
    //if the both addresses are checked in the interface settings
    this.gdsInterfaceSettings = this.appStateService.getCurrentArrayInterfaceSettings();
    if(this.gdsInterfaceSettings != null && this.gdsInterfaceSettings.length > 0){
      this.gdsInterfaceSettings.forEach((element) => {
        if(element.DataSource.startsWith('Apollo') && element.IsEnabled === true) {
          element.Sections.forEach((item) => {
            item.Controls.forEach((control) => {
              if(control.DisplayText.includes('Delivery') && control.Value === "True"){
                this.isDeliveryAddressEnabled = true;
              }
              if(control.DisplayText.includes('Billing') && control.Value === "True"){
                this.isBillingAddressEnabled = true;
              }
            })
          })                   
        }
    });  
    
      this.sameAddress.setValue(this.isDeliveryAddressEnabled && 
        this.isBillingAddressEnabled && this.sameAddress.value == true ? false : true);      
    }  
    if(this.paymentMethod.value == InsurancePaymentMethodType.CC && this.sameAddress.value == false){
      this.addressLine1.setValue(this.booking.OrderInformation.BillingInformation.CardholderAddress1.Value);
      this.city.setValue(this.booking.OrderInformation.BillingInformation.CardholderCity.Value);
      this.stateOfResidence.setValue(this.booking.OrderInformation.BillingInformation.CardholderState.Value);
      this.zipCode.setValue(this.booking.OrderInformation.BillingInformation.CardholderZipCode.Value);
    }

    const isRetryPending = this.appStateService.getBookingInfoViewType() === BookingTypes.Pending && !this.booking.OrderInformation.IsPurchaseConfirmed;
    if (this.isModify || isRetryPending) {
      if (this.getModifiedPrice() < 0) {
        this.nameOnCard.disable();
        this.cardNumber.disable();
        this.expMonth.disable();
        this.expYear.disable();
      }

      this.checkoutForm.patchValue({
        nameOnCard: `${this.booking.TravelInformation.PrimaryTraveler.Info.FirstName.Value} ${this.booking.TravelInformation.PrimaryTraveler.Info.LastName.Value}`,
        addressLine1: this.booking.TravelInformation.PrimaryTraveler.Address.Address1.Value,
        addressLine2: this.booking.TravelInformation.PrimaryTraveler.Address.Address2.Value,
        city: this.booking.TravelInformation.PrimaryTraveler.Address.City.Value,
        stateOfResidence: this.booking.TravelInformation.PrimaryTraveler.Address.State.Value,
        zipCode: this.booking.TravelInformation.PrimaryTraveler.Address.ZipCode.Value,
        isPayMethodCashWithheld: this.booking.OrderInformation.BillingInformation.IsPayMethodCashWithheld.Value,
        paymentMethod: this.booking.OrderInformation.PaymentMethod,
        cardNumber: this.booking.OrderInformation.BillingInformation.CardNumber.Value
      });
      if (!isRetryPending) {
        this.checkoutForm.disable();
      }
    }
    if (this.appStateService.getIsConfirmationEmailedToAgent() && this.booking.BookingStatus == 0) {
      this.checkoutForm.patchValue({
        secondaryEmail: this.agentInfo.EmailAddress || this.booking.OrderInformation.AgentEmail || ''
      });
    }
    else {
      if(this.booking.BookingStatus!=0) {
        var fullfilmentSecondaryEmail = '';
        if(this.booking!=null && this.booking.OrderInformation!=null && this.booking.OrderInformation.BillingInformation!=null && this.booking.OrderInformation.BillingInformation.FulfillmentPrimaryEmail!=null && this.booking.OrderInformation.BillingInformation.FulfillmentSecondaryEmail.Value!=null)    {
          fullfilmentSecondaryEmail = this.booking.OrderInformation.BillingInformation.FulfillmentSecondaryEmail.Value;
        }
        this.checkoutForm.patchValue({secondaryEmail: fullfilmentSecondaryEmail});
      }
    }

    if (this.booking.BookingGroupID !== 0 && this.booking.OrderInformation.PaymentMethod) {
      this.paymentMethod.patchValue(this.booking.OrderInformation.PaymentMethod);
    }
    const primaryEmail = this.setPrimaryEmail();
    this.checkoutForm.patchValue({primaryEmail});
    this.isLoaded = true;
    this.initializeExpirationYear();
    this.states = this.universalConfigService.getStates();      
  }
  /**
   * checks if Fulfillment primary address provided and return it or return primary traveler email address
   * @returns {string}
   */
  setPrimaryEmail(): string {
    if (this.booking.OrderInformation && this.booking.OrderInformation.BillingInformation
      && this.booking.OrderInformation.BillingInformation.FulfillmentPrimaryEmail && this.booking.OrderInformation.BillingInformation.FulfillmentPrimaryEmail.Value) {
      return this.booking.OrderInformation.BillingInformation.FulfillmentPrimaryEmail.Value;
    } else if (this.booking.TravelInformation && this.booking.TravelInformation.PrimaryTraveler
      && this.booking.TravelInformation.PrimaryTraveler.EmailAddress && this.booking.TravelInformation.PrimaryTraveler.EmailAddress.Value) {
      return this.booking.TravelInformation.PrimaryTraveler.EmailAddress.Value;
    } else {
      return '';
    }
  }

  /**
   * Checks if should be disabled
   * @returns {boolean}
   */
  isDisabled(): boolean {
    return this.isPendingPolicyView;
  }

  /**
   * Triggers the credit card fields or reciept field based on payment method
   * @returns {boolean}
   */
  triggerCreditCardForm(): boolean {
    // payment value not selected fresh policy
    if (this.paymentMethod.value === null) {
      // isPayMethodCashWithheld bool true
      return this.isPayMethodCashWithheld.value;
    } else {
      // if payment method selected
      return this.paymentMethod.value === InsurancePaymentMethodType.AC || this.paymentMethod.value === InsurancePaymentMethodType.CW;
    }
  }

  /**
   * show buttons based on content block
   * @param {string} type
   * @returns {boolean}
   */
  showButton(type: string): boolean {
    if (type === 'purchase') {
      if (!this.isPolicyView && !this.isPendingPolicyView && !this.isEditFlightsPolicyView) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Handles the patch value of isPaymentMethodChange based on payment method selected
   * @returns {void}
   */
  handlePaymentMethodChange(): void {
    this.cannotPurchaseSGIndividualPolicy();
    this.isPayMethodCashWithheld.patchValue(this.paymentMethod.value === InsurancePaymentMethodType.CW);
  }

  /**
   * TODO
   */
  onEditFlights(): void {

  }

  /**
   * checks and updates a flag for whether to show
   * the Purchase/Acknowledge errors
   * @returns {boolean}
   */
  checkPurAckError(): boolean {
    this.showPurAckError = !this.readPurchaseAgreement.value || !this.readCertificate.value;
    this.ccData = (this.checkoutForm.controls.paymentMethod.value === InsurancePaymentMethodType.CC) ? $('#cardNumberJs').validateCreditCard() : null;
    return this.showPurAckError;
  }

  /**
   * event handler for the purchase button
   * checks for Purchase/Acknowledge errors
   * @returns {void}
   */
  purchase(): void {
    if (!this.checkPurAckError()) {
      this.bookingPurchase.emit('bookingPurchase');
    }
  }

  /**
   * event handler for retry purchasing pending quotes
   * @returns {void}
   */
  retryBooking(): void {
    if (!this.checkPurAckError()) {
      this.retryPendingBooking.emit('retryPendingBooking');
    }
  }

  /**
   * event handler for the Submit button
   * checks for Purchase/Acknowledge errors
   * @returns {void}
   */
  update(): void {
    if (!this.checkPurAckError()) {
      this.modifyPurchase.emit('modifyPurchase');
    }
  }

  /**
   * Save small group policy
   * @returns {void}
   */
  saveQuote(): void {
    this.bookingSaveQuote.emit('bookingSaveQuote');
  }

  /**
   * event handler for the recalculate price button
   * @returns {void}
   */
  rCalculatePrice(): void {
    this.checkPriceButtonClick = true;
    this.reCalculatePrice.emit('reCalculatePrice');
    this.isCheckPriceButtonClick.emit(this.checkPriceButtonClick);
  }

  /**
   *  opens the purchase agreement
   * @returns {void}
   */
  openPurchaseAgreement(): void {
    window.open('https://content.allianzpartnerservices.com/purchase-agreement/');
  }

  /**
   *  opens a link to ati
   * @returns {void}
   */
  openPlanDetails(): void {
    let url = `${environment.ProductTC}&productid=${this.booking.ProductInformation.ProgramProducts[0].Product.PSProductId}&state`;
    if (this.booking.TravelInformation.PrimaryTraveler.Address.State.Value !== '') {
      url += `=${this.booking.TravelInformation.PrimaryTraveler.Address.State.Value}`;
    }
    window.open(url);
  }

  /**
   * on accordions to update local isOpen variable
   * @param {boolean} open
   * @returns {void}
   */
  isOpenChange(open: boolean): void {
    this.isOpen = open;
  }

  /**
   * Get submit text for checkout form
   * @returns {string}
   */
  getSubmitText(): string {
    return 'Complete Purchase';
  }

  /**
   * patches the billing address when the same address checkbox is checked
   * @returns {void}
   */
  onCopyBillingAddressChange(): void {
    if (this.sameAddress.value) {
      this.patchCardholderAddress(
        this.booking.TravelInformation.PrimaryTraveler.Address.Address1.Value,
        this.booking.TravelInformation.PrimaryTraveler.Address.Address2.Value,
        this.booking.TravelInformation.PrimaryTraveler.Address.City.Value,
        this.booking.TravelInformation.PrimaryTraveler.Address.State.Value,
        this.booking.TravelInformation.PrimaryTraveler.Address.ZipCode.Value
      );
    } else {
      this.patchCardholderAddress();
    }
    this.updateBooking.emit('updateBooking');
  }

  /**
   * Runs the patch value on the reactive form
   * @param {string} addressLine1
   * @param {string} addressLine2
   * @param {string} city
   * @param {string} stateOfResidence
   * @param {string} zipCode
   * @returns {void}
   */
  patchCardholderAddress(
    addressLine1Input: string = '',
    addressLine2Input: string = '',
    cityInput: string = '',
    stateOfResidenceInput: string = '',
    zipCodeInput: string = ''
  ): void {
    this.checkoutForm.patchValue({
      addressLine1: addressLine1Input,
      addressLine2: addressLine2Input,
      city: cityInput,
      stateOfResidence: stateOfResidenceInput,
      zipCode: zipCodeInput
    });
  }

  /**
   * Indicates whether or not to show agency credit card radio
   * @returns {boolean}
   */
  showAgencyCreditCard(): boolean {
    return (this.activatedRoute.snapshot.params.groupid || this.paymentMethod.value === this.paymentMethodType.AC) ? true : false;
  }

  /**
   * Show small group checkout buttons
   * @returns {void}
   */
  showSmallGroupButtons(): boolean {
    return (this.activatedRoute.snapshot.params.groupid) ? true : false;
  }

  /**
   * Show the credit card radio button
   * @returns {void}
   */
  showCreditCard(): boolean {
    if (
      (!this.isPolicyView && !this.isPendingPolicyView && !this.isEditFlightsPolicyView) ||
      (this.isPolicyView && !this.booking.OrderInformation.BillingInformation.IsPayMethodCashWithheld.Value) ||
      (this.isPendingPolicyView && !this.booking.OrderInformation.BillingInformation.IsPayMethodCashWithheld.Value) ||
      (this.isEditFlightsPolicyView && !this.booking.OrderInformation.BillingInformation.IsPayMethodCashWithheld.Value)
    ) {
      return true;
    }
    return false;
  }
  /**
   * return whether property on BillingInformation is valid or not
   * @param {string} propertyName
   * @returns {boolean}
   */
  isBillingFieldValid(propertyName: string): boolean {
    if (this.booking && this.booking.OrderInformation && this.booking.OrderInformation.BillingInformation
      && !this.booking.OrderInformation.BillingInformation[propertyName].IsValid) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Show the cash withheld radio button
   * @returns {boolean}
   */
  showCashWithheld(): boolean {
    if (
      (!this.isPolicyView && !this.isPendingPolicyView && !this.isEditFlightsPolicyView && this.booking.OrderInformation.IsCashWithHeldEnabled) ||
      (this.isPolicyView && this.booking.OrderInformation.BillingInformation.IsPayMethodCashWithheld.Value) ||
      (this.isPendingPolicyView && this.booking.OrderInformation.BillingInformation.IsPayMethodCashWithheld.Value) ||
      (this.isEditFlightsPolicyView && this.booking.OrderInformation.BillingInformation.IsPayMethodCashWithheld.Value)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Is price changed
   * @returns {boolean}
   */
  isPriceChanged(): boolean {
    if ((this.priceBeforeModify === this.booking.OrderInformation.Price) && !this.quoteChanged) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Toggles the puchase policy button for small group purchases
   * @returns {void}
   */
  cannotPurchaseSGIndividualPolicy(): boolean {
    if (this.paymentMethod.value === null || this.triggerCreditCardForm()) {
      return true;
    } else if (
      this.nameOnCard.value !== '' &&
      this.cardNumber.value !== '' &&
      this.expMonth.value !== '' &&
      this.expYear.value !== ''
    ) {
      return false;
    }
    return true;
  }

  /**
   * Trigger whether of not the save record button is disabled
   * @returns {boolean}
   */
  cannotSaveRecord(): boolean {
    return !this.canSaveRecord;
  }

  /**
   * Get modified
   * @returns {number}
   */
  getModifiedPrice(): number {
    return this.booking.OrderInformation.Price - this.priceBeforeModify;
  }
/**
 * check if fuulfillment email valid
 * @returns {boolean}
 */
  checkIfFulfillmentEmailValid(): boolean {
    if (this.booking && this.booking.OrderInformation && this.OrderInformation.BillingInformation
      && this.booking.OrderInformation.BillingInformation.FulfillmentPrimaryEmail) {
      return this.booking.OrderInformation.BillingInformation.FulfillmentPrimaryEmail.IsValid;
    }
    return true;
  }
  ngOnDestroy(): void {
    if (this.checkoutSubscription) {
      this.checkoutSubscription.unsubscribe();
    }
  }
/**
 * check if paymentmethod is cutomer creditcard and return false, otherwise it return true for other payment methods
 * this method used to hide purchase button when payment is not customer creditcard
 * @returns {boolean}
 */
  hideGroupPurchaseButton(): boolean {
    if (!this.isSmallGroupPolicy) {
      return false;
    } else if (this.paymentMethod.value === this.paymentMethodType.CC) {
      return false;
    }
    return true;
  }

  /**
   * gets the values for the expiration year dropdown
   * @returns {void}
   */
  private initializeExpirationYear(): void {
    const today = new Date();
    const todaysYear = today.getFullYear();
    const years: Array<{ value: number; text: string; }> = [];

    for (let i = 0; i < 20; i++) {
      years.push({ value: todaysYear + i, text: (todaysYear + i).toString() });
    }

    this._years = years;
  }
}
