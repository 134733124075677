// Angular Core
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

// Models
import { KeyValue, ErrorMessage } from '@allianz/agent-max-core-lib';
import { AgentProfile } from '../models/interfaces';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UserService } from 'src/app/api/services/user.service';

// 3rd Party
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  userProfileFormGroup: FormGroup;
  isFormSubmissionComplete: boolean = false;
  agentProfile: AgentProfile;
  agentCodeisEditable: boolean;
  roles: KeyValue[];
  workLocations: KeyValue[];
  businessTypes: KeyValue[];
  reminderQuestions: KeyValue[];
  passwordsMatch: boolean = false;
  passwordStrengthSubject: Subject<any> = new Subject();
  reminderQuestion: FormControl = new FormControl('', [Validators.required]);
  reminderAnswer: FormControl = new FormControl('', [Validators.required]);
  password: FormControl = new FormControl('', [Validators.required]);
  confirmPassword: FormControl = new FormControl('', [Validators.required]);
  businessTypeText: FormControl = new FormControl('', [Validators.required]);
  workLocationText: FormControl = new FormControl('', [Validators.required]);
  agentOtherRoleText: FormControl = new FormControl('', [Validators.required]);
  agentCode: FormControl = new FormControl('', [Validators.required]);
  agentLastName: FormControl = new FormControl('', [Validators.required]);
  agentFirstName: FormControl = new FormControl('', [Validators.required]);
  isSubsequentUserAnAdmin: FormControl = new FormControl(true);
  allowManagePolicyAgencyLevel: FormControl = new FormControl(true);
  allowChangeAgentID: FormControl = new FormControl(true);
  allowAgentsToCreateWebAccounts: FormControl = new FormControl(true);
  validationMessages: ErrorMessage[] = [];
  agentEmail: String;

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private userService: UserService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.userProfileFormGroup = this.formBuilder.group({
      reminderQuestion: this.reminderQuestion,
      reminderAnswer: this.reminderAnswer,
      password: this.password,
      confirmPassword: this.confirmPassword,
      businessTypeText: this.businessTypeText,
      workLocationText: this.workLocationText,
      agentOtherRoleText: this.agentOtherRoleText,
      agentCode: this.agentCode,
      agentLastName: this.agentLastName,
      agentFirstName: this.agentFirstName,
      isSubsequentUserAnAdmin: this.isSubsequentUserAnAdmin,
      allowManagePolicyAgencyLevel: this.allowManagePolicyAgencyLevel,
      allowChangeAgentID: this.allowChangeAgentID,
      allowAgentsToCreateWebAccounts: this.allowAgentsToCreateWebAccounts,
      agentEmail: this.agentEmail,
    });
  }

  ngOnInit(): void {
    this.getAgentProfile();
    if (!this.agentProfile.AgentCode.IsEditable) {
      this.userProfileFormGroup.controls.agentCode.disable();
    }
    this.agentFirstName.setValue(this.agentProfile.AgentFirstName.Value);
    this.agentLastName.setValue(this.agentProfile.AgentLastName.Value);
    this.agentCode.setValue(this.agentProfile.AgentCode.Value);
    this.agentEmail = this.agentProfile.AgentEmail.Value;

    this.roles = this.route.snapshot.data.roles;
    this.businessTypes = this.route.snapshot.data.businessTypes;
    this.workLocations = this.route.snapshot.data.workLocations;
    this.reminderQuestions = this.route.snapshot.data.reminderQuestions;
    this.changeDetectorRef.detectChanges();
    
    this.userProfileFormGroup.patchValue({
      agentOtherRoleText: this.agentProfile.AgentOtherRoleText.Value,
      businessTypeText: this.agentProfile.BusinessTypeText.Value,
      workLocationText: this.agentProfile.WorkLocationText.Value,
      reminderQuestion: this.agentProfile.Security.ReminderQuestion.Value
    });

  }

  /**
   * On init send an empty string to initialize the PasswordStrengthComponent
   * @returns {void}
   */
  ngAfterViewInit(): void {
    this.passwordStrengthSubject.next('');
  }

  /**
   * When the new password changes, update the PasswordStrengthComponent accordingly
   * @returns {void}
   */
  updateNewPw(): void {
    this.passwordStrengthSubject.next(this.password.value);
  }

  /**
   * updates the passwordsmatch flag based on input values
   * @returns {void}
   */
  checkPasswordMatch(): void {
    this.passwordsMatch = (this.password.value === this.confirmPassword.value);
  }

  /**
   * gets teh agent profile supplied from the forgot password response
   * it might have validation errors based on the token, so it needs to be handled
   * @returns {void}
   */
  getAgentProfile(): void {
    this.agentProfile = this.appStateService.getAgentProfile();
    if (this.agentProfile.IsValid) {
      if (this.agentProfile.Security.Password.Value !== null) {
        this.password.setValue(this.agentProfile.Security.Password.Value);
      } else {
        this.validationMessages = this.agentProfile.ValidationMessages;
      }
    } else {
      this.validationMessages = this.agentProfile.OperationErrorMessages;
    }
  }

  /**
   * saves the supplied agent profile
   * note that it always updates every field in the form,
   * so hopefully the user was paying attention
   * @returns {void}
   */
  saveAgentProfile(): void {
    this.validationMessages = [];
    this.agentProfile.Security.Password.Value = this.password.value;
    this.agentProfile.AgentFirstName.Value = this.agentFirstName.value;
    this.agentProfile.AgentLastName.Value = this.agentLastName.value;
    this.agentProfile.AgentCode.Value = this.agentCode.value ? this.agentCode.value : '';
    this.agentProfile.AgentOtherRoleText.Value = this.agentOtherRoleText.value;
    this.agentProfile.WorkLocationText.Value = this.workLocationText.value;
    this.agentProfile.BusinessTypeText.Value = this.businessTypeText.value;
    this.agentProfile.Security.ReminderQuestion.Value = this.reminderQuestion.value;
    this.agentProfile.Security.ReminderAnswer.Value = this.reminderAnswer.value;
    this.agentProfile.IsSubsequentUserAnAdmin.Value = this.isSubsequentUserAnAdmin.value;
    this.agentProfile.AllowManagePolicyAgencyLevel.Value = this.allowManagePolicyAgencyLevel.value;
    this.agentProfile.AllowChangeAgentID.Value = this.allowChangeAgentID.value;
    this.agentProfile.AllowAgentsToCreateWebAccounts.Value = this.allowAgentsToCreateWebAccounts.value;
    this.userService.savePendingAgentProfile(this.appStateService.getEmailToken(), this.agentProfile)
      .subscribe((response) => {
        if (response.IsValid) {
          if (response.ValidationMessages === null || response.ValidationMessages.length === 0) {
            if (response.LoginResponse.IsWebEnabled) {
              this.appStateService.setFullAccess(response.LoginResponse.IsWebEnabled);
              this.appStateService.setCredentials(response.LoginResponse);
              this.appStateService.setAdminStatus(response.LoginResponse.IsAdmin);
              this.appStateService.setReportingRight(response.LoginResponse.HasReportingRight);
              this.router.navigate(['home']);

            } else {
              this.isFormSubmissionComplete = true;
            }
          } else {
            this.agentProfile = response;
            this.appStateService.setAgentProfile(response);
            if (response.Security.Password.Value !== '') {
              this.password.setValue(response.Security.Password.Value);
            } else {
              this.password.setValue('');
            }
            this.validationMessages = response.ValidationMessages;
          }
        } else {
          this.validationMessages = response.OperationErrorMessages;
        }
      });

  }
}
