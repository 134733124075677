// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Components
import { ModalComponent } from '../shared/components/modal/modal.component';

// Models
import { ConfirmationViewType, PrintOrEmailTypes, PrintOrEmailReportType, PrintOrEmailDocumentType, QueueTypes } from '../models/enums';
import { PrintOrEmailOptions } from '../models/interfaces';

// corelib
import { ErrorMessage, Booking, FileTypes } from '@allianz/agent-max-core-lib';

// Services
import { AppStateService } from '../shared/services/app-state.service';
import { ReportService } from '../api/services/report.service';
import { UtilityService } from '../shared/services/utility.service';
import { PolicyService } from '../api/services/policy.service';
import { LoadingService } from '../api/services/loading.service';

// Third Party
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { UpdatePNRRequest } from '../models/interfaces/update-pnr-request.interface';
import Swal from 'sweetalert2';
import { GdsService } from '../api/services/gds.service';

@Component({
  selector: 'app-purchase-confirmation',
  templateUrl: './purchase-confirmation.component.html',
  styleUrls: ['./purchase-confirmation.component.scss']
})
export class PurchaseConfirmationComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;
  confirmationViewType: ConfirmationViewType;
  quote: Booking;
  errorMessages: ErrorMessage[] = new Array();
  purchaseConfirmationSubscription: Subscription;
  newOrModifiedBooking: boolean = false;
  isApolloEnabled: boolean = false;
  isExportButton: boolean = false;

  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private utilityService: UtilityService,
    private reportService: ReportService,
    private bsModalService: BsModalService,
    private policyService: PolicyService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private location: Location,
    private gdsService: GdsService
  ) {
    this.newOrModifiedBooking = this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.newOrModifiedBooking;
  }

  ngOnInit(): void {
    this.confirmationViewType = this.appStateService.getConfirmationViewType();
    this.quote = this.route.snapshot.data.booking;
    this.appStateService.setCurrentBookingQuote(this.quote);
    this.isApolloEnabled = this.appStateService.getApolloSettings();
    this.gdsService.checkUpdateOrExportButtonUIByPNR(this.quote.GDSInformation != null ? 
      this.quote.GDSInformation.PNRLocatorNumber : "0"
    , this.appStateService.getCredentials())  
        .subscribe((response) => {
          if (response) {
            if (response) {
              this.isExportButton = true;         
            } 
          }
        })
  }

  /**
   * Navigates back to previous route
   * @returns {void}
   */
  backToQueues(): void {
    // if is a group individual policy then
    if (this.quote.BookingGroupID !== 0 && this.quote.BookingID !== this.quote.BookingGroupID) {
      this.appStateService.setCurrentBookingId(this.quote.BookingGroupID);
      this.router.navigate([`small-group/modifyGroup/${this.quote.BookingGroupID}/policies`]);
    } else {
      this.router.navigate(['queues']);
    }

    if (this.newOrModifiedBooking) {
      this.appStateService.setQueueType(QueueTypes.Confirmed);
      this.router.navigate(['queues']);
    } else {
        this.location.back();
    }
  }

  /**
   * Navigates to print email route after
   * setting up the booking and report name
   * @returns {void}
   */
  onPrintPolicy(): void {
    this.loadingService.open();
    this.purchaseConfirmationSubscription = this.reportService.viewOrEmailBookingDocument('View', PrintOrEmailReportType.TravelInsuranceReceipt, this.quote, {}, this.appStateService.getCredentials())
      .subscribe((response) => {
        if (response.IsValid) {
          this.utilityService.openFile(response.FileContent, FileTypes.PDF);
        } else {
          this.errorMessages = response.ErrorMessages;
        }
      },
      (error) => this.loadingService.close(),
      () => this.loadingService.close());
  }

  /**
   * Navigates to print email route after
   * setting up the booking and report name
   * @returns {void}
   */
  onEmailPolicy(): void {
    let reportName;
    const agent = this.appStateService.getAgentInformation();
    const bookingData = new Booking();
    bookingData.BookingID = this.quote.BookingID;
    bookingData.PrimaryInsuredName = `${this.quote.TravelInformation.PrimaryTraveler.Info.FirstName.Value} ${this.quote.TravelInformation.PrimaryTraveler.Info.LastName.Value}`;
    bookingData.PrimaryInsuredEmail = this.quote.OrderInformation.ConfirmationDetails.Email;
    bookingData.PolicyNumber = this.quote.OrderInformation.ConfirmationDetails.Policies[0].Key;

    const options: PrintOrEmailOptions = {
      HeaderName: '',
      DocumentType: PrintOrEmailDocumentType.BookingDocument,
      ReportType: PrintOrEmailReportType.TravelInsuranceReceipt,
      BookingId: bookingData.BookingID,
      PolicyNumber: bookingData.PolicyNumber,
      ShowViewButton: false,
      EmailInput: {
        ToName: bookingData.PrimaryInsuredName,
        ToEmailAddress: bookingData.PrimaryInsuredEmail,
        SecondaryEmailAddress: bookingData.SecondaryEmail,
        FromEmailAddress: agent.EmailAddress,
        FromName: `${agent.FirstName} ${agent.LastName}`
      }
    };

    if (this.confirmationViewType === ConfirmationViewType.OpenConfirmation) {
      reportName = PrintOrEmailTypes.PurchaseReceiptAndConfirmation;
      options.HeaderName = 'Email Purchase Receipt / Policy Confirmation';
      options.ShowResendFulfillment = true;
    } else {
      reportName = PrintOrEmailTypes.PurchaseReceipt;
      options.HeaderName = 'Email Purchase Receipt';
    }

    this.appStateService.setPrintOrEmailReportName(reportName);
    this.appStateService.setPrintOrEmailData(bookingData, options);
    this.router.navigate(['printoremail']);
  }

  ngOnDestroy(): void {
    if (this.purchaseConfirmationSubscription) {
      this.purchaseConfirmationSubscription.unsubscribe();
    }
  }

  /**
   * Policy Confirmation dialog trigger
   * @returns {void}
   */
  showDialogCancelConfirm(): void {
    const initialState = {
      text: `Are you sure you want to cancel this policy?`,
      dialogIndicator: 'Policy'
    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    const policyCancelSubscription = this.bsModalRef.content.modalResponse.subscribe((modalResponse) => {
      policyCancelSubscription.unsubscribe();
      if (modalResponse) {
        this.loadingService.open();
        const cancelPurchaseSubscription = this.policyService.cancelPurchase(this.quote.BookingID, this.quote.OrderInformation.ConfirmationDetails.Policies[0].Key, this.appStateService.getCredentials())
          .subscribe((response) => {
            this.loadingService.close();
            cancelPurchaseSubscription.unsubscribe();
            if (response.IsCancelSuccessful) {
              this.quote = response.Booking;
              this.appStateService.setCurrentBookingQuote(response.Booking);
            } else {
              this.errorMessages = response.ErrorMessages;
            }
          },
          (error) => this.loadingService.close(),
          () => this.loadingService.close());
      }
    },
    (error) => this.loadingService.close(),
    () => this.loadingService.close());
  }

  /**
   * Update PNR calling Travelport API
   * @returns {void}
   */
  onUpdatePNR(){
    this.loadingService.open();

    const pnrRequest: UpdatePNRRequest = {
      BookingId: this.quote.BookingID != null ? Number(this.quote.BookingID) : 0,
      PSProductId: this.quote.ProductInformation.ProgramProducts[0].ProductId != null ? 
      this.quote.ProductInformation.ProgramProducts[0].ProductId.toString() : "0",
      PNRLocatorNumber: this.quote.GDSInformation != null ? this.quote.GDSInformation.PNRLocatorNumber : "0",
      PNRSource: this.quote.GDSInformation != null ? this.quote.GDSInformation.PNRSource : "0",
      PreviousPNRInfo: this.quote.GDSInformation != null ? this.quote.GDSInformation.PreviousPNRInfo : "0",
      WillBePNRPending: this.quote.GDSInformation != null ? this.quote.GDSInformation.WillBePNRPending : false,
      UniversalRecordLocatorCode: ""
    };

    this.gdsService.updatePNR(pnrRequest, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.IsSucceded) {
            this.appStateService.setUniversalRecordLocatorCode(response.UniversalRecordLocatorCode);
            Swal.fire({
              icon: "success",
              title: 'Success',
              showConfirmButton: true,
              text: 'PNR updated successfully to Apollo/Galileo.'
            }).then((result) => {
              if(result.value){
                this.loadingService.close();
                window.location.reload();
              }                    
            }); 
           
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response.ErrorMessages[0].Description//'There is an error on updating this PNR! '
            })
            this.loadingService.close();
          }
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'System Error, please contact system admin.'
          })
          this.loadingService.close();
        })
  }

  /**
   * Export PNR calling Travelport API
   * @returns {void}
   */
   onExportPNR(){
    this.loadingService.open();

    const pnrRequest: UpdatePNRRequest = {
      BookingId: this.quote.BookingID != null ? Number(this.quote.BookingID) : 0,
      PSProductId: this.quote.ProductInformation.ProgramProducts[0].ProductId != null ? 
      this.quote.ProductInformation.ProgramProducts[0].ProductId.toString() : "0",
      PNRLocatorNumber: this.quote.GDSInformation != null ? this.quote.GDSInformation.PNRLocatorNumber : "0",
      PNRSource: this.quote.GDSInformation != null ? this.quote.GDSInformation.PNRSource : "0",
      PreviousPNRInfo: this.quote.GDSInformation != null ? this.quote.GDSInformation.PreviousPNRInfo : "0",
      WillBePNRPending: this.quote.GDSInformation != null ? this.quote.GDSInformation.WillBePNRPending : false,
      UniversalRecordLocatorCode: this.appStateService.getUniversalRecordLocatorCode()
    };

    this.gdsService.exportPNR(pnrRequest, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.IsSucceded) {
            Swal.fire({
              icon: "success",
              title: 'Success',
              showConfirmButton: true,
              text: 'PNR exported successfully to Apollo/Galileo.'
            })
            this.loadingService.close();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response.Message
            })
            this.loadingService.close();
          }
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'System Error, please contact system admin.'
          })
          this.loadingService.close();
        })
  }
}
